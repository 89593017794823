<template>
  <div class="maxBox">
    <img class="logo" src="../../assets/img/certificate/u92.png" >
    <div class="textBox">
      <h3>证书标题</h3>
      <p v-if="showtime">获奖时间 2019年7月10日</p>
      <span>证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍证书介绍绍证书介绍证书介绍绍证书介绍证书介绍绍证书介绍证书介绍绍证书介绍证书介绍</span>
    </div>
  </div>
</template>
 <script>
 export default {
   props:{
     showtime:Boolean
   }
 }
 </script>
<style lang="scss" scoped>
.maxBox {
  overflow: hidden;
  margin-top: 30px;
  .logo {
    float: left;
    width: 300px;
    height: 200px;
    // background-color: rosybrown;
  }
  .textBox {
    margin-left: 50px;
    float: left;
    margin-top: 10px;
    width: 500px;
    line-height: 19px;
    overflow: hidden;
    h3 {
      font-size: 16px;
    }
    span {
      display: block;
      margin-top: 10px;
      height: 130px;
      overflow: hidden;
    }
    p{
      padding-top: 10px ;
    }
  }
}
</style>