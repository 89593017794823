
<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle :text="textTitle" />
      <certificateBox v-for="(item, index) in 3" :key="index" :showtime="showtime" />
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
import certificateBox from "@/components/certificate/certificateBox";
export default {
  //证书介绍和获奖一览 共页面 用 bear=1 ？获奖：证书
  components: {
    textTitle,
    sidebar,
    certificateBox,
  },
  data() {
    return {
      textTitle: "",
      fingerpostbear: 0, //指南标志位切换路由赋值
      showtime: true, //
    };
  },
  watch: {
    $route() {
      this.fingerpostbear = this.$route.query.bear;
    },
    fingerpostbear() {
      this.gitTitle();
    },
  },
  methods: {
    // 获取 标题
    gitTitle() {
      let textTitleFlag = this.$route.query.bear;
      let arr = [
        {
          bear: 1,
          value: "证书介绍",
        },
        {
          bear: 2,
          value: "获奖一览",
        },
      ];
      this.textTitle = arr.filter(
        (item) => item.bear == textTitleFlag
      )[0].value;
      if (this.textTitle == "获奖一览") {
        this.showtime = true;
      } else {
        this.showtime = false;
      }
    },
  },
  mounted() {
    this.gitTitle();
  },
};
</script>